<template>
    <span class="text-xs py-1 px-2 text-center 
        flex items-center whitespace-nowrap
        font-bold bg-blue-600 text-white rounded-full capitalize">
        {{ item.visibilty }}
    </span>
</template>

<script>
export default {
    name: "badge",
    props: {
        item: {
            type: Object,
            default: () => {},
        },
    },
};
</script>

<style lang="scss" scoped></style>
